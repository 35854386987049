import React from "react";
import { Card, Typography } from "@mui/material";


const LandingPage = () => {
  return (
    <Card sx={{ padding: 5, marginY: 3, marginX: 3 }}>
      <Typography variant="subtitle2">
        โปรดเข้าระบบผ่านลิ้งค์ใน ​Line Group
      </Typography>
    </Card>
  );
};

export default LandingPage;
