import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { getSelectedRoute, setSelectedRoute } from "../utils";
import { Route } from "../interface";
import { getRoutes } from "../api";

interface SelectRouteProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  callback?: (route: string)=> void;
}



const SelectRoute = ({ open, setOpen, callback = (route: string)=>{} }: SelectRouteProps): ReactElement => {
  const [routes,setRoutes]=useState<Route[]>()
  const [selectedRouteState, setSelectedRouteState] = useState(
    getSelectedRoute()
  );

  useEffect(()=>{
    initialData()
  },[])

  const initialData = async() => {
    const response = await getRoutes()
    setRoutes(response.routes)
  }

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open}>
      <DialogTitle>Select Route</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel htmlFor="max-width">Route</InputLabel>
            <Select
              autoFocus
              value={selectedRouteState}
              onChange={(event) => {
                setSelectedRouteState(event.target.value);
              }}
              label="Route"
              inputProps={{
                name: "route",
                id: "route",
              }}
            >
              {routes &&
                routes?.map((item) => {
                  return (
                    <MenuItem key={item.route_name} value={item.route_name}>
                      {item.route_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (selectedRouteState !== "") {
              callback(selectedRouteState)
              setSelectedRoute(selectedRouteState);
              setOpen(false);
            }
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectRoute;
