import React from "react";
import { useEffect, useRef, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Alert, AlertTitle, Box, Button, Card, CardActions, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, useMediaQuery } from "@mui/material";
import { getSelectedRoute, setSelectedFolder as setSelectedFolderToStorage } from "../utils";
import {
  createFolder,
  deleteFolder,
  deleteImages,
  getCountState,
  getFolder,
  getFolderInOrder,
  getImagesUrl,
  sendGroup,
  uploadImageToFolder,
} from "../api";
import { CountStateMap, CurrentPageMap, CustomImage, ImageMap } from "../interface";
import StandardImageList from "../components/StandardImageList";
import Loading from "../components/Loading";
import { userState } from "../state";
import { useRecoilValue } from "recoil";
import ModalConfirm from "../components/ModalConfirm";
import ModalUpload from "../components/ModalUpload";
import Resizer from 'react-image-file-resizer';
import EditedImageList from "./EditedImageList";
import { NONE_GROUP, ROLES } from "../constant";




interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          >
          {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const pageSize = 10
interface UploadImageProps {
  defaultEditedImageUrl?: string;
  groupId: string;
  groupName: string
}

const UploadImage = ({defaultEditedImageUrl, groupId, groupName}: UploadImageProps) => {
  const [currentPage, setCurrentPage] = useState<CurrentPageMap>({});
  const [images, setImages] = useState<ImageMap>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [folders, setFolders] = useState<string[]>([]);
  const [folderInOrder, setFolderInOrder] = useState<string[]>([]);
  const selectedRoute = getSelectedRoute();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const userProfile = useRecoilValue(userState);
  const [openConfirmDeleteFolder, setOpenConfirmDeleteFolder] =
    useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [openConfirmDeleteImage, setOpenConfirmDeleteImage] =
    useState<boolean>(false);
  const [openSentLink, setOpenSentLink] =
    useState<boolean>(false);
  const [openUpload, setopenUpload] =
    useState<boolean>(false);
  const [showSendLinkSuccess, setShowSendLinkSuccess] =
    useState<boolean>(false);
  const [showSendLinkError, setShowSendLinkError] =
    useState<boolean>(false);
  const [totalData, setTotalData] = useState<CurrentPageMap>({});
  const isXs = useMediaQuery("(max-width:600px)");
  const [uploadData, setUploadData] = useState('');
  const [progress, setProgress] = useState(0);
  const [tab, setTab] = useState(0);
  const [countStateMap, setCountStateMap] = useState<CountStateMap>({});
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [selectedGroupName, setSelectedGroupName] = useState<string>("")
  const [defaultImage, setDefaultImage] = useState<string>("")


  useEffect(()=>{
    if(defaultEditedImageUrl !== undefined && defaultEditedImageUrl !== ""){
      setTab(1)
      setDefaultImage(defaultEditedImageUrl)
      setSelectedGroupId(groupId)
      setSelectedGroupName(groupName)
      const defaultImageUrlSplit = defaultEditedImageUrl.split("/")
      setSelectedFolder(defaultImageUrlSplit[defaultImageUrlSplit.length - 1])
    }
  },[defaultEditedImageUrl])

  useEffect(()=>{
    if(groupName !== undefined && groupName !== "" && groupId !== undefined && groupId !== ""){
      setSelectedGroupId(groupId)
      setSelectedGroupName(groupName)
    }
  },[groupName,groupId])

  const downloadImages = async (folders: string[]) => {
    if (selectedRoute) {
      if (folders.length > 0) {
        const imageMap: ImageMap = {};
        folders.forEach(async (folder) => {
          if (folder) {
            setCurrentPage({ ...currentPage, [folder]: 1 });
            const newImages = await downloadImageByFolder(folder, 1);
            imageMap[folder] = newImages;
          }
        });
        setTimeout(() => {
        setImages(imageMap);},1000)
      }
    }
  };

  const loadMore = async (folder: string) => {
    const page = (currentPage[folder] || 1) + 1
    const oldImages = images[folder]
    const newImages = await downloadImageByFolder(folder, page);
    setImages({ ...images, [folder]: [...oldImages, ...newImages] });
    setCurrentPage({ ...currentPage, [folder]: page });
  };

  const downloadImageByFolder = async (
    folder: string,
    page: number
  ): Promise<CustomImage[]> => {
    const { images: imageList, total } = await getImagesUrl(selectedRoute, folder, pageSize, page, userProfile.userId, [ROLES.ADMIN,ROLES.IMAGE_EDITED].includes(userProfile.role), NONE_GROUP);
    const newImages = imageList.map((item: string) => {
      const newImageUrl = item.replace(
        "http://localhost:8002",
        "https://sgjshop-api.dodev.me"
      );
      return {
        original: newImageUrl,
        caption: newImageUrl,
      };
    });
    const newTotalData = totalData
    newTotalData[folder] = total
    setTotalData(newTotalData)
    return newImages;
  };


  const handleUploadImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setIsLoading(true);
      const folder = selectedFolder;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file) {
          const scale = 0.5;
          const dimensions = await getImageDimensions(file);
          const resizedFile = await resizeImage(file, dimensions.width * scale, dimensions.height * scale);

          // Create a FormData object
          const formData = new FormData();
          formData.append("upload_file", resizedFile, file.name);
          formData.append("route", selectedRoute);
          formData.append("folder", folder);

          // Create a config object for axios with progress callback
          const config = {
            onUploadProgress: (progressEvent: any) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              // Update your progress bar state or UI here
              console.log(`Upload progress: ${percentCompleted}%`);
              // setProgress(percentCompleted);
            },
          };

          try {
            // Use axios to make the upload request with progress tracking
            await uploadImageToFolder(formData, config);

            // After successful upload, update other state variables
            setCurrentPage({ ...currentPage, [folder]: 1 });
            const newImages = await downloadImageByFolder(folder, 1);
            setImages({ ...images, [folder]: newImages });
          } catch (error) {
            console.error('Error uploading image:', error);
            // Handle error as needed
          }
        }
      }
      setIsLoading(false);
      // // Reset progress after upload is complete
      // setProgress(0);
    }
  };

  const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        'JPEG', // compressFormat
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri as Blob)
        },
        'blob', // outputType
        0, // minWidth
        0 // minHeight
      );
    });
  };

  useEffect(() => {
    if (selectedRoute && selectedRoute !== "") {
      setSelectedGroupId("")
      setSelectedGroupName("")
      getFolders(selectedRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);



  const getFolders = async (route: string): Promise<void> => {
    setIsLoading(true);
    const folders = await getFolder(route, userProfile.role);
    const folderInOrderList = await getFolderInOrder(route, userProfile.role);
    downloadImages(folders);
    getCountStateData(folderInOrderList);
    setTimeout(() => {
      setFolders(folders);
      setFolderInOrder(folderInOrderList)
      setIsLoading(false);
    }, 1500);
  };

  const getCountStateData = async (folders: string[]) => {
    if (selectedRoute) {
      if (folders.length > 0) {
        const newCountStateMap: CountStateMap = {};
        folders.map(async (folder) => {
          if (folder) {
            const newCountState = await getCountState(selectedRoute, folder);
            newCountStateMap[folder] = newCountState;
          }
        });
        setCountStateMap(newCountStateMap);
      }
    }
  }

  const handleDeleteFolder = async (route: string, name: string) => {
    await deleteFolder({ route, name });
    getFolders(route);
    setSelectedFolder("");
  };

  const handleCreateFolder = async (route: string) => {
    await createFolder({ route });
    getFolders(route);
  };

  const handleSelectedImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageSrc: string
  ) => {
    if (event.target.checked) {
      setSelectedImage([...selectedImage, imageSrc]);
    } else {
      setSelectedImage([...selectedImage.filter((item) => item !== imageSrc)]);
    }
  };

  const onDeleteImages = async (folderName: string) => {
    setIsLoading(true);
    const key = selectedRoute + "/" + folderName + "/";
    const imagesForDelete = selectedImage.filter((item) => item.includes(key));
    await deleteImages(
      selectedRoute,
      folderName,
      imagesForDelete.map((item) => item.split(key)[1])
    );
    setCurrentPage({ ...currentPage, [folderName]: 1 });
    const newImages = await downloadImageByFolder(folderName, 1);
    setSelectedImage(
      selectedImage.filter((item) => !imagesForDelete.includes(item))
    );
    setImages({ ...images, [folderName]: newImages });
    setSelectedFolder("");
    setIsLoading(false);
  };

  const getDeleteDisabled = (folderName: string): boolean => {
    const key = selectedRoute + "/" + folderName;
    const imagesForDelete = selectedImage.filter((item) => item.includes(key));

    return imagesForDelete.length === 0;
  };

  const handleGenerateLink = async (route: string, folder: string) => {
    const res = await sendGroup(route, folder, userProfile.displayName)
    setShowSendLinkSuccess(res.status)
    setShowSendLinkError(!res.status)
  }

  const handleUploadData = (data: any) => {
    setUploadData(data);
    console.log('Data from upload:', data);
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
      setTab(newTab);
      if(newTab === 0){
        setSelectedGroupId("");
        setSelectedGroupName("");
      }
  };


  return isLoading ? (
    <Loading />
  ) : (
    <>


      <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Manage Route" {...a11yProps(0)} />
                <Tab label="Order List" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={tab} index={0}>
        <Box style={{ display: "flex", padding: '16px' }}>
          <Typography variant="h4">{selectedRoute}</Typography>
          <Button
            style={{ marginLeft: "auto" }}
            sx={{ bgcolor: `primary.main` }}
            color="primary"
            onClick={() => {
              handleCreateFolder(selectedRoute);
            }}
            variant="contained"
            disabled={folders.length > 0}
          >
            Create Folder
          </Button>
        </Box>

        {showSendLinkSuccess && (
          <Box sx={{ marginY: 2 }}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
            </Alert>
          </Box>
        )
        }

        {showSendLinkError && (
          <Box sx={{ marginY: 2 }}>
            <Alert severity="error">
              <AlertTitle>Something went wrong, Please try again later.</AlertTitle>
            </Alert>
          </Box>
        )
        }

        <ModalConfirm
          title={"Delete Image"}
          content={
            "Do you want to delete image in folder : " +
            selectedFolder.replace("T", " ")
          }
          open={openConfirmDeleteImage}
          handleClose={() => {
            setOpenConfirmDeleteImage(false);
          }}
          handleConfirm={function (): void {
            setOpenConfirmDeleteImage(false);
            onDeleteImages(selectedFolder);
          }}
        />

        <ModalConfirm
          title={"Sent link to line group"}
          content={
            `Do you want to sent link route: ${selectedRoute}, folder: ${selectedFolder.replace("T", " ")}`
          }
          open={openSentLink}
          handleClose={() => {
            setOpenSentLink(false);
          }}
          handleConfirm={function (): void {
            handleGenerateLink(selectedRoute, selectedFolder)
            setOpenSentLink(false);
          }}
        />

        <ModalConfirm
          title={"Delete Folder"}
          content={"Do you want to delete " + selectedFolder.replace("T", " ")}
          open={openConfirmDeleteFolder}
          handleClose={() => {
            setOpenConfirmDeleteFolder(false);
          }}
          handleConfirm={function (): void {
            setOpenConfirmDeleteFolder(false);
            handleDeleteFolder(selectedRoute, selectedFolder);
          }}
        />

        <ModalUpload
          open={openUpload}
          handleClose={() => {
            setopenUpload(false);
          }}
          handleConfirm={function (): void {
            setopenUpload(false);
            // handleDeleteFolder(selectedRoute, selectedFolder);
          }}
          onUploadData={handleUploadData}
        />

        {folders.map((item) => {
          return (
            <Card sx={{ padding: 2, marginY: 3 }} key={item}>

              <Box style={{ display: "flex" }}>
                <Box style={{ marginRight: "10px" }}>
                  {" "}
                  <Typography variant="h5">{item.replace("T", " ")} {` Loaded:${images[item].length}/${totalData[item]}`}</Typography>
                </Box>
                {isXs && (
                  <>
                    <Button
                      sx={{ fontSize: 10 }}
                      color="error"
                      size="small"
                      onClick={() => {
                        setSelectedFolder(item);
                        setOpenConfirmDeleteFolder(true);
                      }}
                      variant="outlined"
                    >
                      Delete Folder
                    </Button>
                    <Button
                      style={{ marginLeft: 3 }}
                      color="primary"
                      size="medium"
                      onClick={() => {
                        setSelectedFolder(item);
                        setOpenSentLink(true)
                        setShowSendLinkSuccess(false)
                      }}
                      variant="contained"
                      sx={{ bgcolor: `primary.main` }}
                    >
                      Generate Link
                    </Button></>

                )}
                {!isXs && (
                  <>
                    <Button
                      style={{ marginLeft: 3 }}
                      color="error"
                      size="medium"
                      onClick={() => {
                        setSelectedFolder(item);
                        setOpenConfirmDeleteFolder(true);
                      }}
                      variant="outlined"
                    >
                      Delete Folder
                    </Button>
                    <Button
                      style={{ marginLeft: 3 }}
                      color="primary"
                      size="medium"
                      onClick={() => {
                        setSelectedFolder(item);
                        setOpenSentLink(true)
                        setShowSendLinkSuccess(false)
                      }}
                      variant="contained"
                      sx={{ bgcolor: `primary.main` }}
                    >
                      Generate Link
                    </Button>
                  </>
                )}
              </Box>
              <CardContent sx={{ padding: '0px' }}>
                
                <StandardImageList
                  itemData={images[item]?.map(item => {
                    const imageUrlSplit = item.original.split("/");
                    const originalRoute = imageUrlSplit[imageUrlSplit.length - 4] + '/' + imageUrlSplit[imageUrlSplit.length - 3]
                    const newImageUrl = item.original.replace(originalRoute, "thumbnail")
                    return {
                      ...item,
                      original: newImageUrl
                    }
                  })}
                  handleSelectedImageChange={handleSelectedImageChange}
                />

                <Button
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  size="medium"
                  onClick={() => {
                    loadMore(item)
                  }}
                  variant="contained"
                  sx={{ bgcolor: `primary.main` }}
                  disabled={images[item].length >= totalData[item]}
                >
                  {`Load More:${images[item].length}/${totalData[item]}`}
                </Button>
                {/* Progress bar */}
                {progress > 0 && progress < 100 && (
                  <div>
                    <p>Uploading: {progress}%</p>
                    <progress value={progress} max="100" />
                  </div>
                )}
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleUploadImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  multiple
                />
                {isXs && (
                  <>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        setSelectedFolder(item);
                        fileInputRef.current?.click();
                      }}
                      variant="contained"
                      sx={{ bgcolor: `primary.main` }}
                    >
                      Upload
                    </Button>
                    <Button
                      color="error"
                      size="small"
                      onClick={() => {
                        setSelectedFolder(item);
                        setOpenConfirmDeleteImage(true);
                      }}
                      variant="outlined"
                      disabled={getDeleteDisabled(item)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </CardActions>
            </Card>
          );
        })}

        {folders.length === 0 && (
          <Card sx={{ padding: 5, marginY: 3 }}>
            <Typography variant="subtitle2">No folder list</Typography>
          </Card>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <Box style={{ display: "flex", padding: '16px' }}>
            <Typography variant="h4">{selectedRoute}</Typography>
        </Box>
        
        {selectedGroupId === "" && folderInOrder.map((item) => {
          return (
              <Card sx={{ padding: 2, marginY: 3, overflow: "auto" }} key={item}>
                <CardContent sx={{ padding: '0px' }}>
                  <Box style={{ display: "flex", padding: '16px' }}>
                    <Typography variant="h4">{item}</Typography>
                  </Box>
                  <Table sx={{}} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Group Name</TableCell>
                        <TableCell align="center">Open</TableCell>
                        <TableCell align="center">Confirm</TableCell>
                        <TableCell align="center">Reject</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {countStateMap[item]?.map((row) => (
                        <TableRow
                          key={row.group_id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          
                        >
                          <TableCell component="th" scope="row" onClick={()=>{
                            setSelectedFolderToStorage(item)
                            setSelectedFolder(item)
                            setDefaultImage("no_default")
                            setSelectedGroupId(row.group_id)
                            setSelectedGroupName(row.group_name)
                            
                          }}>
                          <Typography >
                                {row.group_name}
                          </Typography>
                            
                          </TableCell>
                          <TableCell align="center">{row.open}</TableCell>
                          <TableCell align="center">{row.confirmed}</TableCell>
                          <TableCell align="center">{row.rejected}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
              
          );
        })}

        {selectedGroupId !== "" && selectedFolder !== "" && <EditedImageList defaultEditedImageUrl={defaultImage || 'no_default'} groupName={selectedGroupName} groupId={selectedGroupId} /> }

        {folders.length === 0 && tab === 0 && (
          <Card sx={{ padding: 5, marginY: 3 }}>
            <Typography variant="subtitle2">No folder list</Typography>
          </Card>
        )}
        {folderInOrder.length === 0 && tab === 1 && (
          <Card sx={{ padding: 5, marginY: 3 }}>
            <Typography variant="subtitle2">No folder list</Typography>
          </Card>
        )}
        { selectedGroupId && <Button
            style={{ marginLeft: "auto" }}
            color="primary"
            size="medium"
            onClick={() => {
              setSelectedGroupId("");
              setSelectedGroupName("");
              getFolders(selectedRoute);
            }}
            variant="contained"
            sx={{ bgcolor: `primary.main` }}
            
          >
            Back
        </Button>}
      </CustomTabPanel>
    </>
  );
};

export default UploadImage;
