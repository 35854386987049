import React from "react";
import { Alert, AlertTitle, Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { getOderList, updateOrder } from "../api";
import StandardImageList from "./StandardImageList";
import { CustomImage, Log, Order } from "../interface";
import ModalChangeStateImage from "./ModalChangeStateImage";
import { CONFIRMED_STATE, OPEN_STATE, REJECTED_STATE } from "../constant";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
  
function a11yProps(index: number) {
return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
};
}


const UserOrderHistory = ({
    route,
    userId,
    folder,
    countEdited,
    userRole,
    username,
    defaultImageUrl
  }: {
    route: string
    userId: string
    folder: string
    countEdited: number
    userRole: string
    username: string
    defaultImageUrl: string
  }): React.ReactElement => {
    const [tab, setTab] = useState(0);
    const [imageStateOpen, setImageStateOpen] = useState<CustomImage[]>([]);
    const [imageStateConfirmed, setImageStateConfirmed] = useState<CustomImage[]>([]);
    const [imageStateRejected, setImageStateRejected] = useState<CustomImage[]>([]);
    const [showModalChangeStateImage, setShowModalChangeStateImage] =useState(false);
    const [selectedState, setSelectedState] = useState(OPEN_STATE);
    const [imageIndex,setImageIndex] = useState(0);
    const [showChangeImageStateSuccess, setShowChangeImageStateSuccess]  = useState(false)
    const [showChangeImageStateError, setShowChangeImageStateError]  = useState(false)
    const [defaultImage, setDefaultImage] = useState<string>("")
    useEffect(()=>{
        getOrders()
    },[countEdited])

    useEffect(()=>{
        if(defaultImageUrl !== ""){
            setDefaultImage(defaultImageUrl)
        }
    },[defaultImageUrl])

    useEffect(()=>{
        if(defaultImage !== ""){
            const image = [...imageStateOpen, ...imageStateConfirmed, ...imageStateRejected].find(image=> image.original === defaultImage)
            if(image){
                let index: number | null = null
                switch(image.state) {
                    case OPEN_STATE: 
                        index = imageStateOpen.findIndex(item=> item.original == image.original)
                        setTab(0)
                        break

                    case CONFIRMED_STATE: 
                        index = imageStateConfirmed.findIndex(item=> item.original == image.original)
                        setTab(1)
                        break

                    case REJECTED_STATE: 
                        setTab(2)
                        index = imageStateRejected.findIndex(item=> item.original == image.original)
                        break
                    default:
                        index = null
                }
                if(index){
                    setImageIndex(index);
                    setSelectedState(image.state || OPEN_STATE)
                    setShowModalChangeStateImage(true)
                }

            }
        }
    },[imageStateOpen, imageStateConfirmed, imageStateRejected, defaultImage])

    const findLastMatch = (arr: Log[], value: string): Log | null => {
        return arr.reduce<Log | null>((lastMatch, item) => {
          return item.to_state === value ? item : lastMatch;
        }, null);
      };

    const getCustomImage = (data: Order[], state: string) => {
        return data.filter((item: Order) => item.state == state).map((item: Order)=> {
            return {
                original: item.image_url,
                caption: item.image_url,
                id: item._id,
                rejectedMessage: state == REJECTED_STATE ? findLastMatch(item.logs, state)?.message : "",
                groupId: item.group_id,
                state: item.state
              };
        })
    }
    
    const getOrders = async () => {
        const { data = [] } = await getOderList(route, userId,folder);
        setImageStateOpen(getCustomImage(data, OPEN_STATE))
        setImageStateRejected(getCustomImage(data, REJECTED_STATE))
        setImageStateConfirmed(getCustomImage(data, CONFIRMED_STATE))
    }

    const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
        setTab(newTab);
    };

    const onClickImage = (_image: CustomImage, index: number, state: string) => {
        setImageIndex(index);
        setSelectedState(state)
        setShowModalChangeStateImage(true)
    };

    const getImages = (tab: number): CustomImage[] => {
        const imageList = [imageStateOpen, imageStateConfirmed, imageStateRejected]
        return imageList[tab]
    }

    const handleChangeState = async (image: CustomImage, newState: string, message: string) => {
        const reusult = await updateOrder(image.original, newState, userId, username, message)
        getOrders()
        setShowModalChangeStateImage(false)
        setShowChangeImageStateError(!reusult.status)
        setShowChangeImageStateSuccess(reusult.status)
        setDefaultImage("")
    }

    return <>

        {showChangeImageStateSuccess && (
            <Box sx={{ marginY: 2 }}>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
            </Alert>
            </Box>
        )}
        {showChangeImageStateError && (
            <Box sx={{ marginY: 2 }}>
            <Alert severity="error">
                <AlertTitle>Something went wrong, Please try again later.</AlertTitle>
            </Alert>
            </Box>
        )}

        {showModalChangeStateImage && (
            <ModalChangeStateImage
                images={getImages(tab)}
                imageIndex={imageIndex}
                showModal={showModalChangeStateImage}
                handleClose={() => {
                    setShowModalChangeStateImage(false);
                    setDefaultImage("")
                }}
                state={selectedState}
                userRole={userRole}
                userId={userId}
                handleChangeState={handleChangeState}
            />
        )}
        {!showModalChangeStateImage && <>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Open" {...a11yProps(0)} />
                    <Tab label="Confirmed" {...a11yProps(1)} />
                    <Tab label="Rejected" {...a11yProps(2)} />
                </Tabs>
                <CustomTabPanel value={tab} index={0}>
                    <StandardImageList itemData={imageStateOpen} onClickImage={(_image: CustomImage, index: number) => {
                        onClickImage(_image, index, OPEN_STATE)
                    }} />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                    <StandardImageList itemData={imageStateConfirmed} onClickImage={(_image: CustomImage, index: number) => {
                        onClickImage(_image, index, CONFIRMED_STATE)
                    }} />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={2}>
                    <StandardImageList itemData={imageStateRejected} onClickImage={(_image: CustomImage, index: number) => {
                        onClickImage(_image, index, REJECTED_STATE)
                    }} />
                </CustomTabPanel>
            </>
        }
    </>
  }

  export default UserOrderHistory;