import React from "react";
import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { getFolder, getFolderInOrderByUser, getImagesUrl, getOderList } from "../api";
import { setSelectedFolder as setSelectedFolderToStorage, getSelectedRoute } from "../utils";
import Loading from "../components/Loading";
import { CurrentPageMap, CustomImage, CustomImageAndTotal, ImageMap, Log, Order, OrderMap } from "../interface";
import { useRecoilValue } from "recoil";
import { userState } from "../state";
import StandardImageList from "../components/StandardImageList";
import ModalEditImage from "../components/ModalEditImage";
import UserOrderHistory from "../components/UserOderHistory";
import UserOrderAgain from "../components/UserOrderAgain";
import UserOrderHistoryAll from "../components/UserOderHistoryAll";
import { NONE_GROUP, OPEN_STATE, ROLES } from "../constant";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const pageSize = 100;

const EditImage = ({defaultEditedImageUrl, groupName}: {defaultEditedImageUrl?: string, groupName: string}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedRoute = getSelectedRoute();
  const userProfile = useRecoilValue(userState);
  const [images, setImages] = useState<ImageMap>({});
  const [folders, setFolders] = useState<string[]>([]);
  const [imageUrlForEdit, setImageUrlForEdit] = useState<string>("");
  const [imageIndex, setImageIndex] = useState(0);
  const [totalData, setTotalData] = useState<CurrentPageMap>({});
  const [currentPage, setCurrentPage] = useState<CurrentPageMap>({});
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [tab, setTab] = useState(0);
  const [countEdited, setCountEdited] = useState(0);
  const [orders, setOrders] = useState<OrderMap>({})
  const [justOrder, setJustOrder] = useState<string[]>([])
  const [defaultImage, setDefaultImage] = useState<string>("")
  const [allFolderInOrder, setAllFolderInOrder] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(()=>{
    if(selectedRoute && userProfile.userId) getAllFolderByUser()
  },[userProfile.userId, selectedRoute])

  useEffect(()=>{
    if(defaultEditedImageUrl !== undefined && defaultEditedImageUrl !== ""){
      setTab(2)
      setDefaultImage(defaultEditedImageUrl)
    }
  },[defaultEditedImageUrl])

  useEffect(() => {
    if (selectedRoute && selectedRoute !== "") {
      getFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);


  const getAllFolderByUser = async (): Promise<void> => {
    const allFolders = await getFolderInOrderByUser(selectedRoute,userProfile.userId);
    setAllFolderInOrder(allFolders)
  }

  const getFolders = async (): Promise<void> => {
    setIsLoading(true);
    const folders = await getFolder(selectedRoute, userProfile.role);
    if (folders.length > 0) {
      setFolders(folders);
      downloadImages(folders);
      downloadOrders(folders);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getImageFullList = async (allImage: CustomImage[],page:number, folder: string): Promise<CustomImageAndTotal> => {
    const {images:newImages, total} = await downloadImageByFolder(folder, page);
    const summaryImages = [...allImage, ...newImages];

    const imageMap: ImageMap = {};
    imageMap[folder] = summaryImages;
    setImages(imageMap);

    if(summaryImages.length >= total || newImages.length == 0){
      return {
        images: summaryImages,
        total
      }
    }else{
      return await getImageFullList(summaryImages, page + 1, folder)
    }
  }

  const downloadImages = async (folders: string[]) => {
    if (selectedRoute) {
      if (folders.length > 0) {
        folders.map(async (folder) => {
          if (folder) {
            setCurrentPage({ ...currentPage, [folder]: 1 });
            await getImageFullList([], 1, folder);
          }
        });
      }
    }
  };

  const downloadImageByFolder = async (
    folder: string,
    page: number
  ): Promise<CustomImageAndTotal> => {
    const { images, total } = await getImagesUrl(selectedRoute, folder, pageSize, page, userProfile.userId, userProfile.role == ROLES.ADMIN, userProfile.groupId || NONE_GROUP);
    const newImages = images.map((item: string) => {
      const newImageUrl = item.replace(
        "http://localhost:8002",
        "https://sgjshop-api.dodev.me"
      );
      return {
        original: newImageUrl,
        caption: newImageUrl,
      };
    });
    setTotalData({ ...totalData, [folder]: total });
    return {images: newImages, total};
  };


  const downloadOrders = async (folders: string[]) => {
    if (selectedRoute) {
      if (folders.length > 0) {
        const orderMap: OrderMap = {};
        folders.map(async (folder) => {
          if (folder) {
            const newOrders = await downloadOrderByFolder(folder)
            orderMap[folder] = newOrders;
            setJustOrder(newOrders.map((item: Order)=> item.original_image_url))
          }
        });
        setOrders(orderMap)
      }
    }
  };

  const downloadOrderByFolder = async (folder: string) => {
      const { data = [] } = await getOderList(selectedRoute, userProfile.userId, folder);
      return data
  }

  

  const onClickImage = (image: CustomImage, index: number) => {
    setImageUrlForEdit("");
    setImageUrlForEdit(image.original);
    setImageIndex(index)
  };

  const changeImageEditUrl = (imageForEdit: ImageMap,newIndex: number) => {
    setIsLoading(true)
    setImageIndex(newIndex)
    setImageUrlForEdit("");
    setTimeout(()=>{
      const image = imageForEdit[selectedFolder]
      if(image){
        const imageSrc = image[newIndex]
        if(imageSrc){
          setImageUrlForEdit(imageSrc.original);
        }
      }
      setIsLoading(false)
    }, 10)
    
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
    if(newTab !== 2){
      setDefaultImage("")
    }
  };

  const handleNext = () => {
    if(imageIndex < images[selectedFolder].length - 1){
      const newIndex = imageIndex + 1
      changeImageEditUrl(images, newIndex)
    }else{
      setImageUrlForEdit("");
      setIsLoading(false);
    }
  }

  const handleConfirmOrder = async (status: boolean, originalImageUrl: string, newOrder:Order) => {
    setIsLoading(true)
    if(status){
      setCountEdited(countEdited + 1)
      setTotalData({ ...totalData, [selectedFolder]: totalData[selectedFolder] - 1 });
      setJustOrder([...justOrder, originalImageUrl])
      setOrders({ ...orders, [selectedFolder]: [newOrder, ...orders[selectedFolder]] });
      const newImagesInFolder = images[selectedFolder].filter(image => image.original !== originalImageUrl)
      const newImageMap = { ...images, [selectedFolder]:  newImagesInFolder}
      setImages(newImageMap);
      if(imageIndex < newImageMap[selectedFolder].length - 1){
        changeImageEditUrl(newImageMap, imageIndex)
      }else if(imageIndex - 1 >= 0){
        changeImageEditUrl(newImageMap, imageIndex - 1)
      }else{
        setImageUrlForEdit("");
      }
    }else{
      setIsLoading(false)
    }
  }

  const scrollToImage = (imageSrc: string) => {
    const imageElement = document.querySelector(`img[alt^="${imageSrc}"]`);
    if (imageElement) {
      imageElement.scrollIntoView({ behavior: 'smooth', block: 'center', });
    }
  };

  return (
    <>
      <Loading show={isLoading}/>
      {<ModalEditImage
          imageUrl={imageUrlForEdit}
          handleClose={(status, message) => {
            const imageForScroll = imageUrlForEdit;
            setImageUrlForEdit("");
            if(!status){
              setErrorMessage(message);
            }else{
              setTimeout(()=>{
                scrollToImage(imageForScroll);
              }, 10)
            }
          }}
          handleConfirm={handleConfirmOrder}
          handleBack={()=>{
            if(imageIndex > 0){
              const newIndex = imageIndex - 1
              changeImageEditUrl(images, newIndex)
            }else{
              setImageUrlForEdit("");
              setIsLoading(false);
            }
          }}
          handleNext={handleNext}
          username={userProfile.displayName}
          groupId={userProfile.groupId}
          userId={userProfile.userId}
          profileUrl={userProfile.pictureUrl}
          showModal={!isLoading && imageUrlForEdit !== ""}
        />
      }

      {errorMessage !== "" && (
              <Box sx={{ marginY: 2 }}>
                <Alert severity="error">
                    <AlertTitle>{errorMessage}</AlertTitle>
                </Alert>
              </Box>
      )}

      {!isLoading && imageUrlForEdit === "" && <Box style={{ display: "flex", padding: '10px' }}>
        <Typography variant="h4">{groupName}</Typography>
      </Box>
      }

      {folders?.map((item) => {
        return (
          <Card sx={{ padding: 2, marginY: 3, display: !isLoading && imageUrlForEdit === "" ? "block" : "none" }} key={item}>
            <CardContent sx={{ padding: '0px' }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="รายการ" {...a11yProps(0)} />
                <Tab label="สั่งซ้ำ" {...a11yProps(1)} />
                <Tab label="รายการที่สั่ง" {...a11yProps(2)} />
                <Tab label="ประวัติย้อนหลัง" {...a11yProps(3)} />
              </Tabs>
              <CustomTabPanel value={tab} index={0}>
                <Box style={{ display: "flex" }}>
                  <Typography variant="h5">
                    {item.replace("T", " ")} {` Loaded:${images[item]?.length || 0}/${totalData[item] || 0}`}
                  </Typography>
                </Box>
                <StandardImageList
                  itemData={images[item]?.map(item => {
                    const imageUrlSplit = item.original.split("/");
                    const originalRoute = imageUrlSplit[imageUrlSplit.length - 4] + '/' + imageUrlSplit[imageUrlSplit.length - 3]
                    const newImageUrl = item.original.replace(originalRoute, "thumbnail")
                    return {
                      ...item,
                      original: newImageUrl
                    }
                  })}
                  onClickImage={(_image: CustomImage, index: number)=>{
                    setErrorMessage("");
                    const originalImage = images[item][index];
                    onClickImage(originalImage, index);
                    setSelectedFolder(item)
                    setSelectedFolderToStorage(item)
                  }}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                {tab === 1 && <UserOrderAgain orders={orders[item]?.filter(item=> item.state  === OPEN_STATE) || []} folder={item} /> }
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={2}>
                {tab === 2 && <UserOrderHistory defaultImageUrl={defaultImage} countEdited={countEdited} route={selectedRoute} userId={userProfile.userId} username={userProfile.displayName} folder={item} userRole={userProfile.role} /> }
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={3}>
                {tab === 3 && allFolderInOrder?.map(item=>{
                  return <UserOrderHistoryAll key={item+selectedRoute+userProfile.userId} countEdited={countEdited} route={selectedRoute} userId={userProfile.userId} username={userProfile.displayName} userRole={userProfile.role} folder={item} />;
                })}
              </CustomTabPanel>
            </CardContent>
          </Card>
        );
      })}

      {!isLoading && folders.length === 0 && (
        <Card sx={{ padding: 5, marginY: 3 }}>
          <Typography variant="subtitle2">No folder list</Typography>
        </Card>
      )}
    </>
  );
};

export default EditImage;
